<div>
    <h2 class="mat-h2">Tools</h2>
  </div>
  
  <mat-table [dataSource]="dataSource">
  
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <mat-header-cell *matHeaderCellDef> No.</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.position}} </mat-cell>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="link">
      <mat-header-cell *matHeaderCellDef> Link</mat-header-cell>
      <mat-cell *matCellDef="let element">
  
        <ng-container *ngIf="element.link != null">
  
  
          <a href="{{element.link}}"
             target="_blank">
            <button mat-mini-fab color="primary">
              <mat-icon>save_alt</mat-icon>
            </button>
          </a>
        </ng-container>
        <ng-container *ngIf="element.link == null"></ng-container>
  
      </mat-cell>
    </ng-container>
  
  
    <ng-container matColumnDef="doku">
      <mat-header-cell *matHeaderCellDef> Dokumentation</mat-header-cell>
      <mat-cell *matCellDef="let element">
  
        <ng-container *ngIf="element.doku != ''">
  
  
          <a href="{{element.doku}}"
             target="_blank">
            <button mat-mini-fab >
              <mat-icon>chrome_reader_mode</mat-icon>
            </button>
          </a>
          &nbsp;
          <ng-container *ngIf="element.doku.includes('LoCo3')">
            <button mat-mini-fab (click)="openBedingungenDialog()">
              <mat-icon>info</mat-icon>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="element.doku == ''"></ng-container>
      </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  