import { Component } from '@angular/core';

export interface PeriodicElement {
  tool : string,
  hersteller : string,
  bemerkung : string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    tool: "7Zip",
    hersteller: "7Zip",
    bemerkung: "https://www.7-zip.de/faq.html"
  },
  {
    tool: "RSTCLI",
    hersteller: "Intel",
    bemerkung: "https://www.intel.com/content/www/us/en/download/19462/intel-rstcli-pro-for-intel-optane-memory.html"
  },
  {
    tool: "IntelSysInfo",
    hersteller: "Intel",
    bemerkung: "https://www.intel.com/content/www/us/en/download/17949/system-information-retrieval-utility-sysinfo.html"
  },
  {
    tool: "ARCCONF",
    hersteller: "Adaptec/Microsemi",
    bemerkung: "https://storage.microsemi.com/en-us/speed/raid/storage_manager/arcconf_v3_03_23668_zip.php"
  },
  {
    tool: "DevCon",
    hersteller: "Microsoft",
    bemerkung: "https://docs.microsoft.com/en-us/windows-hardware/drivers/devtest/devcon"
  },
  {
    tool: "DriveLetterViewer",
    hersteller: "NirSoft",
    bemerkung: "https://www.nirsoft.net/utils/drive_letter_view.html"
  },
  {
    tool: "DriverView",
    hersteller: "NirSoft",
    bemerkung: "https://www.nirsoft.net/utils/driverview.html"
  },
  {
    tool: "SmartCTL",
    hersteller: "SmartMonTools",
    bemerkung: "https://www.smartmontools.org/browser/trunk/smartmontools/smartctl.8.in"
  },
  {
    tool: "IPMICFG",
    hersteller: "Supermicro",
    bemerkung: "https://www.supermicro.com/en/solutions/management-software/ipmi-utilities"
  },
  {
    tool: "USBDeView",
    hersteller: "NirSoft",
    bemerkung: "https://www.nirsoft.net/utils/usb_devices_view.html"
  },
  {
    tool: "LSIGet",
    hersteller: "LSI/AVAGO/Broadcom",
    bemerkung: "https://www.broadcom.com/support/knowledgebase/1211161499563/lsiget-data-capture-script"
  }
];


@Component({
  selector: 'app-loco-dialog',
  templateUrl: './loco-dialog.component.html',
  styleUrls: ['./loco-dialog.component.sass']
})
export class LocoDialogComponent {
  datasource = ELEMENT_DATA;
  displayedColumns: string[] = ['tool', 'hersteller', 'bemerkung'];
}
