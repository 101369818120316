
<h2 mat-dialog-title>Loco Tool</h2>
<mat-dialog-content class="mat-typography">

  <div class="container">
    <mat-table [dataSource]="datasource">
      <ng-container matColumnDef="tool">
        <mat-header-cell *matHeaderCellDef> Tool</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.tool}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="hersteller">
        <mat-header-cell *matHeaderCellDef> Hersteller</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.hersteller}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="bemerkung">
        <mat-header-cell *matHeaderCellDef> Bemerkung</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.bemerkung.includes('http')">
            <a href="{{element.bemerkung}}">Link</a>
          </ng-container>
          <ng-container *ngIf="!element.bemerkung.includes('http')">
            {{element.bemerkung}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <br>
    <h2>
      Haftungsauschluss
    </h2>
    <h3>
      Diese Software steht nicht in direkter Verbindung mit der ""bluechip Computer
      AG."" Alle Eingriffe und Arbeiten erfolgen auf eigene Gefahr. Es kann keine
      Haftung oder Gewährleistung für eventuelle Schäden oder Folgeschäden in
      jeglicher Hinsicht übernommen werden. Weiterhin besteht kein Anspruch
      dass die angebotenen Software und Informationen auch in Ihrer Einsatz-
      umgebung die erwünschten Ergebnisse erzielt. Die Entscheidung darüber
      ob und in welcher Form Sie die angebotene Software und Informationen nutzen
      liegt daher allein bei Ihnen.  Sollte es bei Ihnen zu unbeschriebenen
      Zuständen kommen, so unterlassen Sie bitte jegliche Eingriffe. Es ist jede
      Haftung im Zusammenhang mit Ihrer Nutzung dieser Software oder Informationen
      ausgeschlossen. Bitte beachten Sie die Empfehlungen von der ""bluechip Computer AG""
      für die Durchführung von Wartungsintervallen.
      ACHTUNG: Es werden alle CMD-Consolen geschlossen! Ist dies nicht gewünscht, beenden Sie das Programm.
      ACHTUNG: Die Zwischenablage wird ebenfalls gelöscht! Powershell-Consolen werden geschlossen.
      Diese Software ist lediglich auf ""bluechip Computer AG""
      Server erstellt und ist auch nur auf diesen getestet.
      Jegliche Veränderungen an diesem Programm sind untersagt.
      Wir weisen ausdrücklich darauf hin, dass grundlegende Teile dieses Softwarepakets
      Eigentum der einzelner Hersteller bzw. Programmierer sind und dessen Lizenzbestimmung
      unterliegen. Auf Wunsch werden wir Quellen von ggf. OSS o.ä. hersausgeben.
      Allgemeine öffentliche Lizenz/weniger allgemeine öffentliche Lizenz. Bestimmte Komponenten der
      Software können den GNU GPL- oder LGPL-Bedingungen unterliegen, die unter
      <a href="http://www.gnu.org/copyleft/gpl.html">http://www.gnu.org/copyleft/gpl.html</a> und
      <a href="http://www.gnu.org/copyleft/lesser.html">http://www.gnu.org/copyleft/lesser.html</a> oder wie anderweitig
      angegeben eingesehen werden können. In dem Umfang, in dem Sie Software unter dieser Lizenz
      erhalten, die Komponenten enthält, die den GPL- oder LGPL-Bedingungen unterliegen, erklären Sie sich
      damit einverstanden, an alle darin enthaltenen Bedingungen und Einschränkungen gebunden zu sein,
      einschließlich der Beibehaltung aller Urheberrechtshinweise für die Dauer Ihrer Nutzung der Software
      und der Modifizierung und/oder des Weitervertriebs solcher Komponenten nur gemäß den Bedingungen
      der GNU GPL- oder LGPL-Bedingungen.



    </h3>

    <h2>
      Datenschutz
    </h2>
<h3>

  Das Programm sammelt lediglich Konfigurationsdaten. Die Informationen werden nach
  Abschluss des Supportfalls gelöscht. Wir behalten uns dennoch vor Ausschnitte zu Schulungs-
  zwecken ö.ä. zu Speichern und zu Verwenden. Dem können Sie schriftlich widersprechen.
  Unsere kompletten Datenschutzbestimmungen können Sie <a href="https://www.bluechip.de/datenschutz">hier</a> nachlesen.
</h3>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="primary">Schließen</button>
</mat-dialog-actions>
