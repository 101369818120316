import { Component,OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocoDialogComponent } from '../loco-dialog/loco-dialog.component';



export interface PeriodicElement {
  name: string;
  position: number;
  link: string;
  doku: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'LoCo-Tool',
    link: "https://download.bluechip.de/Server/Tools/EZi/LoCo3_Setup.exe?key=Fk7WHrmxWb0qcJHIMXP8aQ",
    doku: 'https://download.bluechip.de/Server/Tools/EZi/LoCo3.pdf?key=_p6E388d6EzHElFBQlHQXw'},
  {position: 2, name: 'BCDosTool',
    link: "https://download.bluechip.de/Server/Tools/EZi/BCDosTool.zip?key=QH554pVYHzBb1StZtgHYDA",
    doku: 'https://download.bluechip.de/Server/Tools/EZi/BCDosTool.pdf?key=gHStV-7E4H1v1qzp4m8pGw'},
  {position: 3, name: 'IPMI-GUI3',
    link: "https://download.bluechip.de/Server/Tools/EZi/IPMIGui3.exe?key=v6J7_mqd_vvC4Kl4N9EBtg",
    doku: 'https://download.bluechip.de/Server/Tools/EZi/IPMIGui3.pdf?key=JzIRbGHtpbuIfVYNaUgxyg'}

];
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.sass']
})
export class SupportComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'link', 'doku'];
  dataSource = ELEMENT_DATA;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openBedingungenDialog() {
    const dialogRef = this.dialog.open(LocoDialogComponent);
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
